@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.method {
  padding: $spacing-lg 0;
  background-color: lighten($background-dark, 3%);

  &__content {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;

    h2 {
      font-size: 28px;
      margin-bottom: $spacing-md;
      color: $text-light;
      line-height: 28px;

      &::after {
        content: '';
        display: block;
        width: 80px;
        height: 4px;
        background: $primary-color;
        margin: $spacing-sm auto 0;
        border-radius: 2px;
      }

      @include responsiveMobile($breakpoint-md) {
        font-size: $font-size-h2;
        line-height: $font-size-h2;
      }
    }

    .intro {
      color: $text-gray;
      font-size: 1.2rem;
      line-height: 1.6;
      margin-bottom: $spacing-lg;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }

    h3 {
      color: $primary-color;
      font-size: $font-size-h3;
      margin-bottom: $spacing-lg;
      font-weight: 800;
      line-height: $font-size-h3;
    }
  }

  .modules {
    display: grid;
    gap: $spacing-md;
    margin-bottom: $spacing-lg;
    
    @include responsive($breakpoint-md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .module-item {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    padding: $spacing-md;
    text-align: left;
    transition: $transition-base;

    &:hover {
      transform: translateY(-5px);
      background: rgba(255, 255, 255, 0.05);
    }

    .check-icon {
      color: $primary-color;
      font-size: 1.5rem;
      margin-bottom: $spacing-sm;
      display: block;
    }

    h4 {
      color: $text-light;
      font-size: 1.2rem;
      margin-bottom: $spacing-sm;
    }

    p {
      color: $text-gray;
      font-size: 1rem;
      line-height: 1.6;
    }
  }

  .conclusion {
    color: $text-gray;
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: $spacing-lg;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-primary {
    @include button-primary;
    font-size: 16px;
    padding: 16px 32px;
  }
}