.teacher {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;

  h2 {
    font-size: 18px;
    font-weight: normal;

    & > p {
      margin: 15px 0;
    }
  }
}

.teacher img {
  display: block;
  width: 500px;
  max-width: 100%;
  margin: 30px auto;
  border-radius: 10px;
  box-shadow: inset 0 0 10px #000, inset 0 0 20px #000;
}

.teacher img:hover {
  transform: scale(1.03);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.btn-shine {
  display: inline-block;
  padding: 12px 48px;
  color: #fff;
  background: linear-gradient(to right, #00bcd380 0, #00bcd3 10%, #868686 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 5s infinite linear;
  animation-fill-mode: forwards;
  font-weight: 600;
  font-size: 36px;
  text-decoration: none;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  margin: 20px 0;
  text-align: center;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 260px;
  }
  100% {
    background-position: 260px;
  }
}

@media (max-width: 768px) {
  .teacher img {
    width: 100%;
    max-width: none;
    border-radius: 0;
  }

  .btn-shine {
    font-size: 26px;
    padding: 10px 36px;
  }

  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 240px;
    }
    100% {
      background-position: 240px;
    }
  }
}

@media (max-width: 480px) {
  .btn-shine {
    font-size: 30px;
    padding: 8px 24px;
  }

  .teacher img {
    max-width: 100%;
  }
}
