@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.inclusions {
  padding: $spacing-lg 0;
  background: linear-gradient(
    to bottom,
    lighten($background-dark, 5%),
    $background-dark
  );

  &__content {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;

    .intro-text {
      margin-bottom: $spacing-lg;
      color: #fff;
    }

    .project-important-info{
      color: $primary-color;
    }

    h2 {
      font-size: 28px;
      margin-bottom: $spacing-lg;
      color: $text-light;

      &::after {
        content: '';
        display: block;
        width: 80px;
        height: 4px;
        background: $primary-color;
        margin: $spacing-sm auto 0;
        border-radius: 2px;
      }

      @include responsiveMobile($breakpoint-md) {
        font-size: $font-size-h2;
      }
    }
  }

  .features {
    display: grid;
    gap: $spacing-md;
    margin-bottom: $spacing-lg;
    
    @include responsive($breakpoint-md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .feature-item {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    padding: $spacing-md;
    transition: $transition-base;

    &:hover {
      transform: translateY(-5px);
      background: rgba(255, 255, 255, 0.05);
    }

    .icon {
      font-size: 2.5rem;
      margin-bottom: $spacing-sm;
    }

    h3 {
      color: $text-light;
      font-size: 1.2rem;
      margin-bottom: $spacing-sm;
    }

    p {
      color: $text-gray;
      font-size: 1rem;
      line-height: 1.6;
    }
  }


  .bonus-section {
    display: grid;
    gap: $spacing-lg;
    margin-bottom: $spacing-lg;
    
    @include responsive($breakpoint-md) {
      grid-template-columns: repeat(2, 1fr);
    }

    .bonus-card {
      background: linear-gradient(
        135deg,
        rgba($primary-color, 0.1),
        rgba($primary-color, 0.05)
      );
      border: 1px solid rgba($primary-color, 0.2);
      border-radius: 16px;
      padding: $spacing-lg;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(
          circle at top right,
          rgba($primary-color, 0.2),
          transparent 70%
        );
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover {
        transform: translateY(-5px);
        border-color: rgba($primary-color, 0.4);

        &::before {
          opacity: 1;
        }
      }

      h3 {
        color: $primary-color;
        font-size: 1.4rem;
        margin-bottom: $spacing-md;
        display: flex;
        align-items: center;
        gap: $spacing-sm;
        font-weight: 700;

        span {
          font-size: 1.8rem;
        }
      }

      p {
        color: $text-gray;
        font-size: 1.1rem;
        line-height: 1.6;
        position: relative;
        z-index: 1;
      }
    }
  }

  .button-primary {
    @include button-primary;
    font-size: 16px;
    padding: 16px 32px;
  }
}