// Variables
$primary-color: #00c0d7;
$background-color: #000000;
$card-background: #111827;
$text-color: #ffffff;
$text-secondary: #9ca3af;
$border-color: rgba(0, 192, 215, 0.2);

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin card-hover {
  transition: all 0.3s ease;
  &:hover {
    border-color: $primary-color;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

// Header styles
.header {
  position: relative;
  height: 400px;
  overflow: hidden;

  &__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
  }

  &__overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, transparent, $background-color);
  }

  &__content {
    position: absolute;
    inset: 0;
    @include flex-center;
    text-align: center;
  }

  &__title {
    font-size: 3.75rem;
    font-weight: bold;
    margin-bottom: 1rem;

    span {
      color: $primary-color;
    }
  }

  &__subtitle {
    font-size: 1.25rem;
    color: $text-secondary;
  }
}

// Main content styles
.main {
  padding: 4rem 0;

  @media (max-width: 768px) {
    padding: 20px;
  }
}

.section {
  margin-bottom: 4rem;

  &__title {
    font-size: 1.875rem;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 2rem;
  }
}

.courses-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.course-card {
  background: $card-background;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid $border-color;
  @include card-hover;

  &__image-container {
    position: relative;
    height: 12rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(to top, $card-background, transparent);
    }
  }

  &__content {
    padding: 1.5rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__icon-wrapper {
    padding: 0.5rem;
    background: rgba($primary-color, 0.1);
    border-radius: 0.5rem;
    margin-right: 1rem;
  }

  &__title-group {
    flex: 1;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  &__meta {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.25rem;
  }

  &__level {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;

    &--beginner {
      background-color: #10b981;
    }
    &--intermediate {
      background-color: #f59e0b;
    }
    &--advanced {
      background-color: #ef4444;
    }
    &--all {
      background-color: #3b82f6;
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    color: #fcd34d;
    font-size: 0.875rem;
  }

  &__description {
    color: $text-secondary;
    margin-bottom: 1rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $text-secondary;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  &__button {
    width: 100%;
    background: $primary-color;
    color: $background-color;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
  &__coming-soon {
    //position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: #fcb327; // ou outra cor de destaque
    color: #000;
    font-weight: bold;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    &:hover {
      //background-color: darken($primary-color, 10%);
      cursor: default; // ou 'auto'
    }

    // Se quiser um efeito “ribbon” em diagonal, aplique transform e rotate
  }
}

// Mentorship form styles
.mentorship {
  background: $card-background;
  border-radius: 0.5rem;
  padding: 2rem;
  border: 1px solid $border-color;

  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  &__icon-wrapper {
    padding: 0.75rem;
    background: rgba($primary-color, 0.1);
    border-radius: 0.5rem;
    color: $primary-color;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }

  &__subtitle {
    color: $text-secondary;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    color: $text-secondary;
  }

  &__input {
    background: darken($card-background, 3%);
    border: 1px solid rgba($text-color, 0.1);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    color: $text-color;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: $primary-color;
    }
  }

  &__textarea {
    @extend .mentorship__input;
    min-height: 100px;
    resize: vertical;
  }

  &__select {
    @extend .mentorship__input;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='white'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1rem;
    padding-right: 2.5rem;
  }

  &__button {
    @extend .course-card__button;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
}
