:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  color: rgb(var(--foreground-rgb));
  overflow-x: hidden !important;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  line-height: 150%;
  color: "#D8D8D8";
}

button {
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  overflow: visible;
}

.btn-buy {
  background: rgb(0, 255, 208);
  background: linear-gradient(
    0deg,
    rgb(213, 244, 238) 0%,
    rgb(0, 255, 208) 100%
  );
  color: #000;
  padding: 15px 20px;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 700;
  width: fit-content;
  text-align: center;
}
.btn-buy a {
  text-decoration: none;
  color: #000;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.content-container {
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.separate-line {
  width: 80%;
  margin: 100px auto;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-title {
  font-size: 30px;
  margin: 20px 0 40px 0;
  color: #fff;
  text-align: center;
  font-weight: 700;
  position: relative;
  margin-bottom: 80px;
  line-height: 50px;
}

.section-subtitle {
  font-size: 1.5rem;
  margin: 20px 0 40px 0;
  color: #fff;
  text-align: center;
  position: relative;
  margin-bottom: 80px;
}

.section-title::after {
  content: "";
  height: 5px;
  width: 100px;
  background-color: #00bcd3;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.world-programming__line {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  /* Ajuste para a terceira coluna ocupar o dobro de espaço */
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}

.world-programming_line-card {
  width: 100%;
  border: 1px solid #00bcd3;
  background-color: #181b20;
  display: flex;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 15px;
  font-weight: bold;
}

.word-programming-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.world-programming_line-text {
  font-weight: bold;
  font-size: 15px;
}

.world-programming_line-text span {
  color: #00bcd3;
}

.card-benefits {
  border-top: 10px solid #00bcd3;
  border-radius: 5px;
  background-color: #181b20;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 50px 0;
  padding: 20px;
  box-sizing: border-box;
  margin: 50px auto;
}
.card-benefits2 {
  border-top: 10px solid #ffffff;
  border-radius: 5px;
  background-color: #181b20;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 50px 0;
  padding: 20px;
  box-sizing: border-box;
  margin: 50px auto;
}

.card-benefits__title {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 20px;
}

.card-benefits__text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.school__content {
  display: flex;
  flex-direction: column;
}

.school_content-text-title {
  font-size: 24px;
  font-weight: 400;
  margin-top: 15px;
  text-align: center;
  color: #fff;
}

.school_content-text-item {
  display: flex;
  gap: 10px;
  font-size: 20px;
  margin: 50px 0;
  justify-content: center;
}

.school_content-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 15px 0;
}

.projects__content h3 {
  font-size: 32px;
  text-align: center;
}

.cards-projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 50px;
  color: #fff;
}

.card-project-active {
  width: 300px;
  border: 1px solid #00bcd3;
  border-radius: 5px;
  display: flex;
  text-align: center;
  padding: 30px 20px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: bold;
}

.card-project {
  width: 300px;
  border: 1px solid #626262;
  border-radius: 5px;
  display: flex;
  text-align: center;
  padding: 30px 20px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  flex: 1;
}

.images-projects {
  display: flex;
  justify-content: center;
}

.project-img {
  width: 100%;
  display: none;
}

.project-img-active {
  width: 100%;
}

.card-info-project {
  border-left: 10px solid #00bcd3;
  border-radius: 5px;
  background-color: #181b20;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  margin: 50px auto;
  display: flex;
  gap: 20px;
  width: 100%;
}

.bonus_content_card {
  border-top: 10px solid #00bcd3;
  border-radius: 5px;
  background-color: #181b20;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 100px;
  box-sizing: border-box;
  margin: 50px auto;
}

.line-bonus {
  border-bottom: 1px solid #00bcd3;
  padding-bottom: 30px;
}

.line-bonus:last-child {
  border-bottom: none;
}

.line-bonus__content {
  display: flex;
  gap: 20px;
  margin: 30px 0;
}

.line-bonus__image {
  height: 15em;
  width: 15em;
}

.line-bonus_content-texts h1 {
  font-size: 26px;
  color: #fff;
  line-height: 35px;
}

.line-bonus_content-texts p {
  font-size: 22px;
  color: #ccc;
}

.teacher {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 50px 0;
}

.teacher h2 {
  font-size: 18px;
  font-weight: normal;
  text-align: left;
}

.card-offer {
  border: 2px solid #00bcd3;
  border-radius: 10px;
  background-color: #181b20;
  padding: 100px;
  box-sizing: border-box;
  width: 100%;
}

.card-offer__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.card-offer__price {
  color: #00bcd3;
  margin-bottom: 50px;
  font-size: 56px;
}

.card-offer__price__all {
  letter-spacing: 7.4px;
  color: #d8d8d8;
  font-size: 16px;
}

.guarantee_access {
  text-align: center;
  font-size: 24px;
  color: #fff;
}

.guarantee_access span {
  color: #00bcd3;
}

@keyframes LUZ {
  70% {
    box-shadow: 0 0 0 30px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}

.offer_button {
  background: rgb(0, 255, 208);
  background: linear-gradient(
    0deg,
    rgb(213, 244, 238) 0%,
    rgb(0, 255, 208) 100%
  );
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  color: #000;
  animation: LUZ 1.5s infinite;
  box-shadow: 0px 0px 20px -5px #00bcd3;
}

.offer_button a {
  color: #000;
  text-decoration: none;
}

.cards-accept {
  margin: 50px auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.guarantee {
  display: flex;
  gap: 20px;
  font-size: 15px;
}
.guarantee img {
  width: 100px;
  margin: 0 auto;
}
.our-trail {
  margin: 50px auto;
}
.trail-step {
  display: flex;
  gap: 30px;
  position: relative;
}
.trail-step img {
  position: relative;
  z-index: 9999;
}
.connecting-line {
  position: absolute;
  top: 150px;
  left: 25px;
  transform: translateX(-50%);
  width: 2px;
  background-color: #00bcd3;
  transition: height 0.5s;
  z-index: 1;
}

.our-trail__content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.active .connecting-line {
  height: 100%;
}

.card-trail {
  background-color: #181b20;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

.bonus_content_card {
  padding: 20px;
}

.world-program-list {
  display: flex;
  justify-content: space-around;
  margin-bottom: 150px;
  margin-top: 50px;
  font-size: 24px;
  line-height: 32px;
}

.programInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
}

.programInfo__title {
  color: #00bcd3;
  font-weight: bold;
  text-align: center;
}

.world-program-list_img {
  margin-bottom: 80px;
  margin-top: 0px;
}

.programInfo__font {
  font-size: 15px;
}

.container__program-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: 50px auto;
}

.btn-buy-info {
  background: rgb(0, 255, 208);
  background: linear-gradient(
    0deg,
    rgb(213, 244, 238) 0%,
    rgb(0, 255, 208) 100%
  );
  color: #000;
  padding: 15px 20px;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 2em;
  width: 300px;
}
.btn-buy-info a {
  text-decoration: none;
  color: #000;
}

.fullstack-desktop {
  display: flex;
  justify-content: center;
}

.fullstack-desktop img {
  width: 85%;
}

.fullstack-mobile {
  display: none;
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 9999;
}

.whatsapp-image {
  width: 50px !important;
}

.retangulo {
  border: 2px solid white;
  padding: 20px;
  text-align: center;
  margin: 0 auto;
  line-height: 35px;
  color:#fff;
}
.guarantee-img {
  width: 100px;
  margin: 10px 0;
}
.fullstack-trail__text {
  margin-bottom: 40px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: #fff;
}
.section-title1 {
  color: #fff;
}
@media (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }

  .world-programming__line {
    display: flex;
    flex-direction: column;
  }

  .world-programming_line-text {
    text-align: center;
  }

  .opportunities-programming {
    margin-top: 500px;
  }

  .word-programming-icon-container img {
    transform: rotate(90deg);
    margin: 30px 0;
  }

  .school__content {
    display: flex;
    flex-direction: column;
  }

  .school_content_code-start {
    width: 90%;
    margin: 0 auto;
  }

  .cards-projects {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .line-bonus__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card-offer {
    padding: 20px;
  }

  .guarantee {
    flex-direction: column;
    text-align: center;
  }
  .fullstack-desktop {
    display: none;
  }
  .fullstack-mobile {
    display: flex;
    justify-content: center;
  }
  .fullstack-mobile img {
    width: 100%;
  }

  .school_content-text-title {
    font-size: 15px;
    font-weight: 400;
    margin-top: 15px;
    text-align: center;
    color: #fff;
  }
  .world-program-list {
    display: flex;
    justify-content: space-around;
    margin-bottom: 150px;
    margin-top: 50px;
    font-size: 15px;
    line-height: 32px;
  }
  .our-trail__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 64px;
    font-size: 15px;
  }
  .school_content-text-item {
    display: flex;
    gap: 10px;
    font-size: 15px;
    margin: 50px 0;
    justify-content: center;
  }
  .line-bonus_content-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .line-bonus_content-texts p {
    font-size: 15px;
    color: #cccccc;
  }
  .guarantee_access {
    text-align: center;
    font-size: 15px;
    color: #fff;
  }
  .card-offer__price {
    color: #00bcd3;
    margin-bottom: 30px;
    font-size: 52px;
  }

  .card-offer__price__all {
    letter-spacing: 7.4px;
    color: #d8d8d8;
    font-size: 12px;
  }
  .section-title {
    font-size: 26px;
  }
  .section-title1 {
    font-size: 22px;
    text-align: center;
    color: #fff !important;
  }
  .fullstack-trail__text {
    margin-bottom: 40px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    color: #fff;
  }
}
