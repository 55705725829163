// Colors
$primary-color: #00bcd3;
$background-dark: #0a0a0a;
$text-light: #ffffff;
$text-gray: #a0a0a0;

// Typography
$font-primary: 'Inter', sans-serif;
$font-size-base: 16px;
$font-size-h1: 24px;
$font-size-h2: 18px;
$font-size-h3: 2rem;

// Spacing
$spacing-xs: 0.5rem;
$spacing-sm: 1rem;
$spacing-md: 2rem;
$spacing-lg: 4rem;

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Animations
$transition-base: all 0.3s ease;