.container {
  max-width: 1440px;
  margin: 0 auto;
}
.content-is-for-you {
  margin-top: -3rem !important;
  &-intern {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & h2 {
      display: flex;
      justify-content: center;
      color: #ffffff;
      font-family: "Hanken Grotesk", Sans-serif;
      font-size: 30px;
      font-weight: 800;
      line-height: 1.2em;
    }
  }
  &-legend {
    color: #00bdd3;
    font-family: "Hanken Grotesk", Sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 41px;
  }
  &-cards {
    display: grid;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    width: 100%;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
  &-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-style: solid;
    border-style: solid;
    border-color: #ffffff50;
    border-width: 1px 1px 1px 1px;
    border-block-start-width: 1px;
    border-inline-end-width: 1px;
    border-block-end-width: 1px;
    border-inline-start-width: 1px;
    border-radius: 10px 10px 10px 10px;
    padding-block-start: 50px;
    padding-block-end: 50px;
    padding-inline-start: 50px;
    padding-inline-end: 50px;
    transition: transform 1s ease;
    width: fit-content;
    box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
    & img {
      width: 60px;
    }
    & h3 {
      color: #fff;
      font-family: "Hanken Grotesk", Sans-serif;
      font-size: 22px;
      font-weight: 300;
      line-height: 1.4em;
    }

    &:hover {
      transform: scale(1.1);
      background-color: #ffffff40;
    }
  }
}
