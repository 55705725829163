@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.earnings {
  padding: $spacing-lg 0;
  background: linear-gradient(
    to bottom,
    $background-dark,
    lighten($background-dark, 5%)
  );

  &__content {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;

    h2 {
      font-size: 28px;
      margin-bottom: $spacing-lg;
      color: $text-light;

      &::after {
        content: '';
        display: block;
        width: 80px;
        height: 4px;
        background: $primary-color;
        margin: $spacing-sm auto 0;
        border-radius: 2px;
      }

      @include responsiveMobile($breakpoint-md) {
        font-size: $font-size-h2;
      }
    }

    &--footer{
      margin-bottom: 2rem;
    }
  }

  .earnings-cards {
    display: grid;
    gap: $spacing-md;
    margin-bottom: $spacing-lg;
    
    @include responsive($breakpoint-md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .earnings-card {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    padding: $spacing-lg;
    transition: $transition-base;
    border: 1px solid rgba($primary-color, 0.1);

    &.featured {
      background: rgba($primary-color, 0.1);
      border-color: rgba($primary-color, 0.3);

      .amount {
        color: $primary-color;
        line-height: 40px;
      }
    }

    &:hover {
      transform: translateY(-5px);
    }

    h3 {
      color: $text-light;
      font-size: 1.3rem;
      margin-bottom: $spacing-md;
    }

    .amount {
      font-size: 2.5rem;
      font-weight: 800;
      color: $text-light;
      margin-bottom: $spacing-md;
      line-height: 40px;
    }

    p {
      color: $text-gray;
      font-size: 1.1rem;
      line-height: 1.6;
    }
  }

  .proof-gallery {
    display: grid;
    gap: $spacing-md;
    margin-bottom: $spacing-lg;
    
    @include responsive($breakpoint-md) {
      grid-template-columns: repeat(3, 1fr);
    }

    .proof-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 12px;
      transition: $transition-base;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .button-primary {
    @include button-primary;
    font-size: 16px;
    padding: 16px 32px;
  }
}