@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.identification {
  padding: $spacing-lg 0;
  background: linear-gradient(
    to bottom,
    lighten($background-dark, 5%),
    $background-dark
  );

  &__content {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;

    h2 {
      font-size: 28px;
      margin-bottom: $spacing-lg;
      color: $text-light;

      &::after {
        content: '';
        display: block;
        width: 80px;
        height: 4px;
        background: $primary-color;
        margin: $spacing-sm auto 0;
        border-radius: 2px;
      }

      @include responsiveMobile($breakpoint-md) {
        font-size: $font-size-h2;
      }
      
    }

     
  }

  .questions {
    margin-bottom: $spacing-lg;
  }

  .question-item {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: $spacing-md;
    margin-bottom: $spacing-md;
    display: flex;
    align-items: flex-start;
    text-align: left;
    transition: $transition-base;

    &:hover {
      transform: translateX(10px);
      background: rgba(255, 255, 255, 0.08);
    }

    .icon {
      font-size: 1.2rem;
      margin-right: $spacing-md;
      flex-shrink: 0;
      margin-top: 0.2rem;
    }

    p {
      color: $text-gray;
      font-size: 1.2rem;
      line-height: 1.6;
      margin: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .conclusion {
    margin-bottom: $spacing-lg;
    
    p {
      color: $text-gray;
      font-size: 1.2rem;
      line-height: 1.6;
      margin-bottom: $spacing-sm;

      &:last-child {
        color: $text-light;
        font-weight: 600;
      }
    }
  }

  .button-primary {
    @include button-primary;
    font-size: 16px;
    padding: 16px 32px;
  }
}